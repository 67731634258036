export const ACCEPT_IMPORT = {
  IMAGE: [".jpeg", ".jpg", ".png", ".JPG", ".JPEG", ".PNG"],
  DOCUMENT: [".PDF", ".pdf", ".doc", ".DOC", ".docx"],
  EXCEL: [".xlsx", ".xls"]
};

export const isImage = ext => {
  return ACCEPT_IMPORT.IMAGE.some(d => d.includes(ext));
};

export const BASE_URL =
  "https://kepegawaian.pauddikdasmen.kemdikbud.go.id/service";
// export const BASE_URL = "https://simpeg.masmana.id";
// export const BASE_URL = "http://localhost:8000";
// export const BASE_URL = "http://localhost:8080/Laravel/simpeg/lumen/public/";

export const NOTIFICATION_TYPE = {
  ACCOUNT: "admin-setting",
  ATTENDANCE: "schedule",
  OTHER: "home"
};

export const APPROVAL_STATUS = {
  CANCEL: "0",
  APPROVE: "1",
  REJECT: "2"
};

export const USER_ROLE = {
  USER: "USER",
  ADMIN: "ADMIN", // All unit kerja
  ADMIN_PUSAT: "ADMIN_PUSAT", //unit utama
  ADMIN_SATKER: "ADMIN_SATKER", //unit kerja 2
  ADMIN_ESELON_3: "ADMIN_ESELON_3", //unit kerja 3
  ADMIN_ESELON_4: "ADMIN_ESELON_4", //unit kerja 4
  MANAGER_SATKER: "MANAGER_SATKER", //Unit kerja 2 (View Only)
  MANAGER_PUSAT: "MANAGER_PUSAT" //Unit kerja 2 (View Only)
};

export const MENU_SIMPEG = {
  DASHBOARD: "home",
  DATA_PEGAWAI: "employee",
  LAPORAN_DATA_PEGAWAI: "employee-report",
  REKAPITULASI_DATA_PEGAWAI: "employee-recap",
  PERUBAHAN_DATA: "updated",
  PERUBAHAN_DATA_PEGAWAI: "employee-updated",
  PERUBAHAN_DATA_VERIFIKASI: "verification-update",
  KEPANGKATAN: "rank",
  PENGATURAN_ADMIN: "admin-setting",
  DATA_UTAMA: "main-data",
  DATA_UNIT_UTAMA: "data-unit-utama",
  DATA_UNIT_GROUP: "data-unit-group",
  DATA_UNIT_KERJA2: "data-unit-kerja-2",
  DATA_UNIT_KERJA3: "data-unit-kerja-3",
  DATA_UNIT_KERJA4: "data-unit-kerja-4",
  DATA_LOKASI: "data-lokasi",
  DATA_GOLONGAN: "data-golongan",
  DATA_KEPANGKATAN: "data-kepangkatan",
  DATA_ESELON: "data-eselon",
  DATA_PENDIDIKAN: "data-pendidikan",
  DATA_KELAS_JABATAN: "data-kelas-jabatan",
  DATA_JABATAN: "data-jabatan",
  DATA_FORMASI_JABATAN: "data-formasi-jabatan",
  DATA_DIGITAL: "data-digital",
  PEMETAAN_GOLONGAN: "pemetaan-golongan",
  BERITA: "news"
};

export const MENU_PRESENSI = {
  DASHBOARD: "home",
  DATA_PRESENSI: "presensi",
  DATA_PRESENSI_DETAIL: "presensi.detail",
  MANAJEMEN_CATATAN: "notes-management",
  PENGAJUAN_CATATAN: "notes-submission",
  HISTORY_CATATAN: "notes-history",
  PENDING_CATATAN: "notes-pending",
  MANAJEMEN_LAPORAN: "report-management",
  CAPAIAN_KINERJA: "performance-achievement",
  KEDISIPLINAN_PEGAWAI: "kedisiplinan-pegawai",
  DETAIL_KEHADIRAN: "detail-kehadiran",
  REKAPITULASI_KEHADIRAN: "rekapitulasi-kehadiran",
  REKAPITULASI_CUTI: "rekapitulasi-cuti",
  LAMPIRAN_1G: "lampiran-1g",
  PENJADWALAN: "penjadwalan",
  PENJADWALAN_UNIT: "penjadwalan-unit",
  PENJADWALAN_LIBUR: "penjadwalan-libur",
  PENJADWALAN_PERIOD: "penjadwalan-period",
  PENJADWALAN_MANUAL: "penjadwalan-manual",
  PENJADWALAN_GROUP_NIP: "penjadwalan-group-nip",
  REKAPITULASI: "summary",
  PENGATURAN: "setting",
  PENGATURAN_MESIN_ABSEN: "machine-setting",
  PENGATURAN_FINGER_ID: "finger-id-setting",
  PENGATURAN_ADMIN: "admin-setting",
  CONFIG_PERSEN_TUKIN: "config-persen-tukin",
  HARI_LIBUR: "holiday",
  BERITA: "news"
};

export const MENU_ACCESS = {
  //USER
  USER_PRESENSI: [
    MENU_PRESENSI.DASHBOARD,
    MENU_PRESENSI.DATA_PRESENSI,
    MENU_PRESENSI.MANAJEMEN_CATATAN,
    MENU_PRESENSI.HISTORY_CATATAN,
    MENU_PRESENSI.PENDING_CATATAN
  ],
  USER_SIMPEG: [MENU_SIMPEG.DASHBOARD, MENU_SIMPEG.DATA_PEGAWAI],

  // ADMIN
  ADMIN_PRESENSI: [
    MENU_PRESENSI.DASHBOARD,
    MENU_PRESENSI.DATA_PRESENSI,
    MENU_PRESENSI.MANAJEMEN_CATATAN,
    MENU_PRESENSI.PENGAJUAN_CATATAN,
    MENU_PRESENSI.HISTORY_CATATAN,
    MENU_PRESENSI.PENDING_CATATAN,
    MENU_PRESENSI.CAPAIAN_KINERJA,
    MENU_PRESENSI.PENJADWALAN,
    MENU_PRESENSI.PENJADWALAN_UNIT,
    MENU_PRESENSI.PENJADWALAN_LIBUR,
    MENU_PRESENSI.PENJADWALAN_PERIOD,
    MENU_PRESENSI.PENJADWALAN_MANUAL,
    MENU_PRESENSI.PENJADWALAN_GROUP_NIP,
    MENU_PRESENSI.MANAJEMEN_LAPORAN,
    MENU_PRESENSI.KEDISIPLINAN_PEGAWAI,
    MENU_PRESENSI.DETAIL_KEHADIRAN,
    MENU_PRESENSI.REKAPITULASI_KEHADIRAN,
    MENU_PRESENSI.REKAPITULASI_CUTI,
    MENU_PRESENSI.REKAPITULASI,
    MENU_PRESENSI.LAMPIRAN_1G,
    MENU_PRESENSI.PENGATURAN,
    MENU_PRESENSI.PENGATURAN_MESIN_ABSEN,
    MENU_PRESENSI.PENGATURAN_FINGER_ID,
    MENU_PRESENSI.PENGATURAN_ADMIN,
    MENU_PRESENSI.BERITA,
    MENU_PRESENSI.CONFIG_PERSEN_TUKIN
  ],
  ADMIN_SIMPEG: [
    MENU_SIMPEG.DASHBOARD,
    MENU_SIMPEG.DATA_PEGAWAI,
    MENU_SIMPEG.LAPORAN_DATA_PEGAWAI,
    MENU_SIMPEG.REKAPITULASI_DATA_PEGAWAI,
    MENU_SIMPEG.PERUBAHAN_DATA,
    MENU_SIMPEG.PERUBAHAN_DATA_PEGAWAI,
    MENU_SIMPEG.PERUBAHAN_DATA_VERIFIKASI,
    MENU_SIMPEG.KEPANGKATAN,
    MENU_SIMPEG.PENGATURAN_ADMIN,
    MENU_SIMPEG.DATA_UTAMA,
    MENU_SIMPEG.DATA_UNIT_UTAMA,
    MENU_SIMPEG.DATA_UNIT_GROUP,
    MENU_SIMPEG.DATA_UNIT_KERJA2,
    MENU_SIMPEG.DATA_UNIT_KERJA3,
    MENU_SIMPEG.DATA_UNIT_KERJA4,
    MENU_SIMPEG.DATA_LOKASI,
    MENU_SIMPEG.DATA_GOLONGAN,
    MENU_SIMPEG.DATA_KEPANGKATAN,
    MENU_SIMPEG.DATA_ESELON,
    MENU_SIMPEG.DATA_PENDIDIKAN,
    MENU_SIMPEG.DATA_KELAS_JABATAN,
    MENU_SIMPEG.DATA_JABATAN,
    MENU_SIMPEG.DATA_FORMASI_JABATAN,
    MENU_SIMPEG.DATA_DIGITAL,
    MENU_SIMPEG.PEMETAAN_GOLONGAN,
    MENU_SIMPEG.BERITA
  ],

  // ADMIN_PUSAT
  ADMIN_PUSAT_PRESENSI: [
    MENU_PRESENSI.DASHBOARD,
    MENU_PRESENSI.DATA_PRESENSI,
    MENU_PRESENSI.MANAJEMEN_CATATAN,
    MENU_PRESENSI.PENGAJUAN_CATATAN,
    MENU_PRESENSI.HISTORY_CATATAN,
    MENU_PRESENSI.PENDING_CATATAN,
    MENU_PRESENSI.CAPAIAN_KINERJA,
    MENU_PRESENSI.PENJADWALAN,
    MENU_PRESENSI.PENJADWALAN_UNIT,
    MENU_PRESENSI.PENJADWALAN_LIBUR,
    MENU_PRESENSI.PENJADWALAN_PERIOD,
    MENU_PRESENSI.PENJADWALAN_MANUAL,
    MENU_PRESENSI.PENJADWALAN_GROUP_NIP,
    MENU_PRESENSI.MANAJEMEN_LAPORAN,
    MENU_PRESENSI.KEDISIPLINAN_PEGAWAI,
    MENU_PRESENSI.DETAIL_KEHADIRAN,
    MENU_PRESENSI.REKAPITULASI_KEHADIRAN,
    MENU_PRESENSI.REKAPITULASI_CUTI,
    MENU_PRESENSI.REKAPITULASI,
    MENU_PRESENSI.LAMPIRAN_1G,
    MENU_PRESENSI.PENGATURAN,
    MENU_PRESENSI.PENGATURAN_MESIN_ABSEN,
    MENU_PRESENSI.PENGATURAN_FINGER_ID,
    MENU_PRESENSI.PENGATURAN_ADMIN,
    MENU_PRESENSI.BERITA,
    MENU_PRESENSI.CONFIG_PERSEN_TUKIN
  ],
  ADMIN_PUSAT_SIMPEG: [
    MENU_SIMPEG.DASHBOARD,
    MENU_SIMPEG.DATA_PEGAWAI,
    MENU_SIMPEG.LAPORAN_DATA_PEGAWAI,
    MENU_SIMPEG.REKAPITULASI_DATA_PEGAWAI,
    MENU_SIMPEG.PERUBAHAN_DATA,
    MENU_SIMPEG.PERUBAHAN_DATA_PEGAWAI,
    MENU_SIMPEG.PERUBAHAN_DATA_VERIFIKASI,
    MENU_SIMPEG.KEPANGKATAN,
    MENU_SIMPEG.PENGATURAN_ADMIN,
    MENU_SIMPEG.DATA_UTAMA,
    MENU_SIMPEG.DATA_UNIT_UTAMA,
    MENU_SIMPEG.DATA_UNIT_GROUP,
    MENU_SIMPEG.DATA_UNIT_KERJA2,
    MENU_SIMPEG.DATA_UNIT_KERJA3,
    MENU_SIMPEG.DATA_UNIT_KERJA4,
    MENU_SIMPEG.DATA_LOKASI,
    MENU_SIMPEG.DATA_GOLONGAN,
    MENU_SIMPEG.DATA_KEPANGKATAN,
    MENU_SIMPEG.DATA_ESELON,
    MENU_SIMPEG.DATA_PENDIDIKAN,
    MENU_SIMPEG.DATA_KELAS_JABATAN,
    MENU_SIMPEG.DATA_JABATAN,
    MENU_SIMPEG.DATA_FORMASI_JABATAN,
    MENU_SIMPEG.DATA_DIGITAL,
    MENU_SIMPEG.PEMETAAN_GOLONGAN,
    MENU_SIMPEG.BERITA
  ],

  // ADMIN_SATKER
  ADMIN_SATKER_PRESENSI: [
    MENU_PRESENSI.DASHBOARD,
    MENU_PRESENSI.DATA_PRESENSI,
    MENU_PRESENSI.MANAJEMEN_CATATAN,
    MENU_PRESENSI.PENGAJUAN_CATATAN,
    MENU_PRESENSI.HISTORY_CATATAN,
    MENU_PRESENSI.PENDING_CATATAN,
    MENU_PRESENSI.CAPAIAN_KINERJA,
    MENU_PRESENSI.PENJADWALAN,
    MENU_PRESENSI.PENJADWALAN_LIBUR,
    MENU_PRESENSI.PENJADWALAN_PERIOD,
    MENU_PRESENSI.PENJADWALAN_MANUAL,
    MENU_PRESENSI.PENJADWALAN_GROUP_NIP,
    MENU_PRESENSI.MANAJEMEN_LAPORAN,
    MENU_PRESENSI.KEDISIPLINAN_PEGAWAI,
    MENU_PRESENSI.DETAIL_KEHADIRAN,
    MENU_PRESENSI.REKAPITULASI_KEHADIRAN,
    MENU_PRESENSI.REKAPITULASI_CUTI,
    MENU_PRESENSI.REKAPITULASI,
    MENU_PRESENSI.LAMPIRAN_1G,
    MENU_PRESENSI.PENGATURAN,
    MENU_PRESENSI.PENGATURAN_FINGER_ID,
    MENU_PRESENSI.PENGATURAN_ADMIN,
    MENU_PRESENSI.BERITA
  ],
  ADMIN_SATKER_SIMPEG: [
    MENU_SIMPEG.DASHBOARD,
    MENU_SIMPEG.DATA_PEGAWAI,
    MENU_SIMPEG.LAPORAN_DATA_PEGAWAI,
    MENU_SIMPEG.REKAPITULASI_DATA_PEGAWAI,
    MENU_SIMPEG.PERUBAHAN_DATA,
    MENU_SIMPEG.PERUBAHAN_DATA_PEGAWAI,
    MENU_SIMPEG.PERUBAHAN_DATA_VERIFIKASI,
    MENU_SIMPEG.KEPANGKATAN,
    MENU_SIMPEG.PENGATURAN_ADMIN,
    MENU_SIMPEG.DATA_UTAMA,
    MENU_SIMPEG.DATA_UNIT_KERJA4,
    MENU_SIMPEG.DATA_LOKASI,
    MENU_SIMPEG.DATA_FORMASI_JABATAN,
    MENU_SIMPEG.BERITA
  ],

  // ADMIN_ESELON_3
  ADMIN_ESELON_3_PRESENSI: [
    MENU_PRESENSI.DASHBOARD,
    MENU_PRESENSI.DATA_PRESENSI,
    MENU_PRESENSI.MANAJEMEN_CATATAN,
    MENU_PRESENSI.PENGAJUAN_CATATAN,
    MENU_PRESENSI.HISTORY_CATATAN,
    MENU_PRESENSI.PENDING_CATATAN,
    MENU_PRESENSI.CAPAIAN_KINERJA,
    MENU_PRESENSI.MANAJEMEN_LAPORAN,
    MENU_PRESENSI.KEDISIPLINAN_PEGAWAI,
    MENU_PRESENSI.DETAIL_KEHADIRAN,
    MENU_PRESENSI.REKAPITULASI_KEHADIRAN,
    MENU_PRESENSI.REKAPITULASI_CUTI,
    MENU_PRESENSI.REKAPITULASI,
    MENU_PRESENSI.LAMPIRAN_1G
  ],
  ADMIN_ESELON_3_SIMPEG: [
    MENU_SIMPEG.DASHBOARD,
    MENU_SIMPEG.DATA_PEGAWAI,
    MENU_SIMPEG.LAPORAN_DATA_PEGAWAI,
    MENU_SIMPEG.REKAPITULASI_DATA_PEGAWAI,
    MENU_SIMPEG.PERUBAHAN_DATA,
    MENU_SIMPEG.PERUBAHAN_DATA_PEGAWAI,
    MENU_SIMPEG.PERUBAHAN_DATA_VERIFIKASI,
    MENU_SIMPEG.KEPANGKATAN,
    MENU_SIMPEG.PENGATURAN_ADMIN,
    MENU_SIMPEG.DATA_UTAMA,
    MENU_SIMPEG.DATA_UNIT_UTAMA,
    MENU_SIMPEG.DATA_UNIT_GROUP,
    MENU_SIMPEG.DATA_UNIT_KERJA2,
    MENU_SIMPEG.DATA_UNIT_KERJA3,
    MENU_SIMPEG.DATA_UNIT_KERJA4,
    MENU_SIMPEG.DATA_LOKASI,
    MENU_SIMPEG.DATA_GOLONGAN,
    MENU_SIMPEG.DATA_KEPANGKATAN,
    MENU_SIMPEG.DATA_ESELON,
    MENU_SIMPEG.DATA_PENDIDIKAN,
    MENU_SIMPEG.DATA_KELAS_JABATAN,
    MENU_SIMPEG.DATA_JABATAN,
    MENU_SIMPEG.DATA_FORMASI_JABATAN,
    MENU_SIMPEG.DATA_DIGITAL,
    MENU_SIMPEG.PEMETAAN_GOLONGAN,
    MENU_SIMPEG.BERITA
  ],

  // ADMIN_ESELON_4
  ADMIN_ESELON_4_PRESENSI: [
    MENU_PRESENSI.DASHBOARD,
    MENU_PRESENSI.DATA_PRESENSI,
    MENU_PRESENSI.MANAJEMEN_CATATAN,
    MENU_PRESENSI.PENGAJUAN_CATATAN,
    MENU_PRESENSI.HISTORY_CATATAN,
    MENU_PRESENSI.PENDING_CATATAN,
    MENU_PRESENSI.CAPAIAN_KINERJA,
    MENU_PRESENSI.MANAJEMEN_LAPORAN,
    MENU_PRESENSI.KEDISIPLINAN_PEGAWAI,
    MENU_PRESENSI.DETAIL_KEHADIRAN,
    MENU_PRESENSI.REKAPITULASI_KEHADIRAN,
    MENU_PRESENSI.REKAPITULASI_CUTI,
    MENU_PRESENSI.REKAPITULASI,
    MENU_PRESENSI.LAMPIRAN_1G
  ],
  ADMIN_ESELON_4_SIMPEG: [
    MENU_SIMPEG.DASHBOARD,
    MENU_SIMPEG.DATA_PEGAWAI,
    MENU_SIMPEG.LAPORAN_DATA_PEGAWAI,
    MENU_SIMPEG.REKAPITULASI_DATA_PEGAWAI,
    MENU_SIMPEG.PERUBAHAN_DATA,
    MENU_SIMPEG.PERUBAHAN_DATA_PEGAWAI,
    MENU_SIMPEG.PERUBAHAN_DATA_VERIFIKASI,
    MENU_SIMPEG.KEPANGKATAN,
    MENU_SIMPEG.PENGATURAN_ADMIN,
    MENU_SIMPEG.DATA_UTAMA,
    MENU_SIMPEG.DATA_UNIT_UTAMA,
    MENU_SIMPEG.DATA_UNIT_GROUP,
    MENU_SIMPEG.DATA_UNIT_KERJA2,
    MENU_SIMPEG.DATA_UNIT_KERJA3,
    MENU_SIMPEG.DATA_UNIT_KERJA4,
    MENU_SIMPEG.DATA_LOKASI,
    MENU_SIMPEG.DATA_GOLONGAN,
    MENU_SIMPEG.DATA_KEPANGKATAN,
    MENU_SIMPEG.DATA_ESELON,
    MENU_SIMPEG.DATA_PENDIDIKAN,
    MENU_SIMPEG.DATA_KELAS_JABATAN,
    MENU_SIMPEG.DATA_JABATAN,
    MENU_SIMPEG.DATA_FORMASI_JABATAN,
    MENU_SIMPEG.DATA_DIGITAL,
    MENU_SIMPEG.PEMETAAN_GOLONGAN,
    MENU_SIMPEG.BERITA
  ],

  // MANAGER_SATKER
  MANAGER_SATKER_PRESENSI: [
    MENU_PRESENSI.DASHBOARD,
    MENU_PRESENSI.DATA_PRESENSI,
    MENU_PRESENSI.MANAJEMEN_CATATAN,
    MENU_PRESENSI.HISTORY_CATATAN,
    MENU_PRESENSI.PENDING_CATATAN,
    MENU_PRESENSI.CAPAIAN_KINERJA,
    MENU_PRESENSI.MANAJEMEN_LAPORAN,
    MENU_PRESENSI.KEDISIPLINAN_PEGAWAI,
    MENU_PRESENSI.DETAIL_KEHADIRAN,
    MENU_PRESENSI.REKAPITULASI_KEHADIRAN,
    MENU_PRESENSI.REKAPITULASI_CUTI,
    MENU_PRESENSI.REKAPITULASI,
    MENU_PRESENSI.LAMPIRAN_1G
  ],
  MANAGER_SATKER_SIMPEG: [
    MENU_SIMPEG.DASHBOARD,
    MENU_SIMPEG.DATA_PEGAWAI,
    MENU_SIMPEG.LAPORAN_DATA_PEGAWAI,
    MENU_SIMPEG.REKAPITULASI_DATA_PEGAWAI,
    MENU_SIMPEG.PERUBAHAN_DATA,
    MENU_SIMPEG.PERUBAHAN_DATA_PEGAWAI,
    MENU_SIMPEG.PERUBAHAN_DATA_VERIFIKASI,
    MENU_SIMPEG.KEPANGKATAN
  ],

  // MANAGER_PUSAT
  MANAGER_PUSAT_PRESENSI: [
    MENU_PRESENSI.DASHBOARD,
    MENU_PRESENSI.DATA_PRESENSI,
    MENU_PRESENSI.MANAJEMEN_CATATAN,
    MENU_PRESENSI.HISTORY_CATATAN,
    MENU_PRESENSI.PENDING_CATATAN,
    MENU_PRESENSI.CAPAIAN_KINERJA,
    MENU_PRESENSI.MANAJEMEN_LAPORAN,
    MENU_PRESENSI.KEDISIPLINAN_PEGAWAI,
    MENU_PRESENSI.DETAIL_KEHADIRAN,
    MENU_PRESENSI.REKAPITULASI_KEHADIRAN,
    MENU_PRESENSI.REKAPITULASI_CUTI,
    MENU_PRESENSI.REKAPITULASI,
    MENU_PRESENSI.LAMPIRAN_1G
  ],
  MANAGER_PUSAT_SIMPEG: [
    MENU_SIMPEG.DASHBOARD,
    MENU_SIMPEG.DATA_PEGAWAI,
    MENU_SIMPEG.LAPORAN_DATA_PEGAWAI,
    MENU_SIMPEG.REKAPITULASI_DATA_PEGAWAI,
    MENU_SIMPEG.PERUBAHAN_DATA,
    MENU_SIMPEG.PERUBAHAN_DATA_PEGAWAI,
    MENU_SIMPEG.PERUBAHAN_DATA_VERIFIKASI,
    MENU_SIMPEG.KEPANGKATAN
  ]
};

export const ACTION_ACCESS = {
  PEGAWAI_PRESENSI: {
    TAB_PEGAWAI: [
      USER_ROLE.USER,
      USER_ROLE.ADMIN,
      USER_ROLE.ADMIN_PUSAT,
      USER_ROLE.ADMIN_SATKER,
      USER_ROLE.ADMIN_ESELON_3,
      USER_ROLE.ADMIN_ESELON_4,
      USER_ROLE.MANAGER_SATKER
    ],
    TAB_PRESENSI: [
      USER_ROLE.USER,
      USER_ROLE.ADMIN_PUSAT,
      USER_ROLE.ADMIN_SATKER,
      USER_ROLE.ADMIN_ESELON_3,
      USER_ROLE.ADMIN_ESELON_4,
      USER_ROLE.MANAGER_SATKER
    ],
    EDIT_PEGAWAI: [
      USER_ROLE.USER,
      USER_ROLE.ADMIN,
      USER_ROLE.ADMIN_PUSAT,
      USER_ROLE.ADMIN_SATKER
    ]
  },
  PERUBAHAN_DATA_PEGAWAI: {
    APPROVE: [
      USER_ROLE.USER,
      USER_ROLE.ADMIN,
      USER_ROLE.ADMIN_PUSAT,
      USER_ROLE.ADMIN_SATKER
    ],
    DECLINE: [
      USER_ROLE.USER,
      USER_ROLE.ADMIN,
      USER_ROLE.ADMIN_PUSAT,
      USER_ROLE.ADMIN_SATKER
    ]
  }
};

export const NAV_LIST_SIMPEG = [
  {
    id: "1",
    name: "Dashboard",
    icon: "dashboard",
    url: MENU_SIMPEG.DASHBOARD
  },
  {
    id: "2",
    name: "Data Pegawai",
    icon: "person",
    url: MENU_SIMPEG.DATA_PEGAWAI
  },
  {
    id: "2",
    name: "Laporan Data Pegawai",
    icon: "library_books",
    url: MENU_SIMPEG.LAPORAN_DATA_PEGAWAI
  },
  {
    id: "2",
    name: "Rekapitulasi Data Pegawai",
    icon: "library_books",
    url: MENU_SIMPEG.REKAPITULASI_DATA_PEGAWAI
  },
  {
    id: "3",
    name: "Data Perubahan",
    icon: "person_add",
    url: MENU_SIMPEG.PERUBAHAN_DATA,
    children: [
      // {
      //   id: "6.1",
      //   name: "Perubahan Data Pegawai",
      //   icon: "",
      //   url: MENU_SIMPEG.PERUBAHAN_DATA_PEGAWAI
      // },
      {
        id: "6.1",
        name: "Verifikasi Perubahan Data",
        icon: "",
        url: MENU_SIMPEG.PERUBAHAN_DATA_VERIFIKASI
      }
    ]
  },
  // {
  //   id: "4",
  //   name: "Kepangkatan",
  //   icon: "stars",
  //   url: MENU_SIMPEG.KEPANGKATAN
  // },
  {
    id: "5",
    name: "Pengaturan Admin",
    icon: "settings",
    url: MENU_SIMPEG.PENGATURAN_ADMIN
  },
  {
    id: "6",
    name: "Data Utama",
    icon: "assignment",
    url: MENU_SIMPEG.DATA_UTAMA,
    children: [
      {
        id: "6.1",
        name: "Data Unit Utama",
        icon: "",
        url: MENU_SIMPEG.DATA_UNIT_UTAMA
      },
      {
        id: "6.1",
        name: "Data Unit Group",
        icon: "",
        url: MENU_SIMPEG.DATA_UNIT_GROUP
      },
      {
        id: "6.1",
        name: "Data Unit Kerja 2",
        icon: "",
        url: MENU_SIMPEG.DATA_UNIT_KERJA2
      },
      {
        id: "6.1",
        name: "Data Unit Kerja 3",
        icon: "",
        url: MENU_SIMPEG.DATA_UNIT_KERJA3
      },
      {
        id: "6.1",
        name: "Data Unit Kerja 4",
        icon: "",
        url: MENU_SIMPEG.DATA_UNIT_KERJA4
      },
      {
        id: "6.1",
        name: "Data Lokasi",
        icon: "",
        url: MENU_SIMPEG.DATA_LOKASI
      },
      {
        id: "6.2",
        name: "Data Golongan",
        icon: "",
        url: MENU_SIMPEG.DATA_GOLONGAN
      },
      {
        id: "6.3",
        name: "Data Kepangkatan",
        icon: "",
        url: MENU_SIMPEG.DATA_KEPANGKATAN
      },
      {
        id: "6.4",
        name: "Data Eselon",
        icon: "",
        url: MENU_SIMPEG.DATA_ESELON
      },
      {
        id: "6.5",
        name: "Data Pendidikan",
        icon: "",
        url: MENU_SIMPEG.DATA_PENDIDIKAN
      },
      {
        id: "6.6",
        name: "Data Kelas Jabatan",
        icon: "",
        url: MENU_SIMPEG.DATA_KELAS_JABATAN
      },
      {
        id: "6.7",
        name: "Data Jabatan",
        icon: "",
        url: MENU_SIMPEG.DATA_JABATAN
      },
      {
        id: "6.8",
        name: "Data Formasi Jabatan",
        icon: "",
        url: MENU_SIMPEG.DATA_FORMASI_JABATAN
      },
      {
        id: "6.9",
        name: "Data Digital",
        icon: "",
        url: MENU_SIMPEG.DATA_DIGITAL
      },
      {
        id: "6.10",
        name: "Pemetaan Golongan",
        icon: "",
        url: MENU_SIMPEG.PEMETAAN_GOLONGAN
      }
    ]
  }
  // {
  //   id: "7",
  //   name: "Berita",
  //   icon: "message",
  //   url: MENU_SIMPEG.BERITA
  // }
];

export const NAV_LIST_PRESENSI = [
  {
    id: "1",
    name: "Dashboard",
    icon: "dashboard",
    url: MENU_PRESENSI.DASHBOARD
  },
  {
    id: "2",
    name: "Data Presensi",
    icon: "person",
    url: MENU_PRESENSI.DATA_PRESENSI
  },
  {
    id: "3",
    name: "M. Catatan",
    icon: "note",
    url: MENU_PRESENSI.MANAJEMEN_CATATAN,
    children: [
      {
        id: "3.1",
        name: "Pengajuan Catatan",
        icon: "",
        url: MENU_PRESENSI.PENGAJUAN_CATATAN
      },
      {
        id: "3.2",
        name: "Pending Catatan",
        icon: "",
        url: MENU_PRESENSI.PENDING_CATATAN
      },
      {
        id: "3.3",
        name: "History Catatan",
        icon: "",
        url: MENU_PRESENSI.HISTORY_CATATAN
      }
    ]
  },
  {
    id: "4",
    name: "Generate SKP Bulanan",
    icon: "speed",
    url: MENU_PRESENSI.CAPAIAN_KINERJA
  },
  {
    id: "6",
    name: "Penjadwalan",
    icon: "event",
    url: MENU_PRESENSI.PENJADWALAN,
    children: [
      {
        id: "7.1",
        name: "Penjadwalan Unit",
        icon: "",
        url: MENU_PRESENSI.PENJADWALAN_UNIT
      },
      {
        id: "7.2",
        name: "Penjadwalan Libur",
        icon: "",
        url: MENU_PRESENSI.PENJADWALAN_LIBUR
      },
      {
        id: "7.3",
        name: "Penjadwalan Period",
        icon: "",
        url: MENU_PRESENSI.PENJADWALAN_PERIOD
      },
      {
        id: "7.4",
        name: "Penjadwalan Manual",
        icon: "",
        url: MENU_PRESENSI.PENJADWALAN_MANUAL
      },
      {
        id: "7.4",
        name: "Penjadwalan Group NIP",
        icon: "",
        url: MENU_PRESENSI.PENJADWALAN_GROUP_NIP
      }
    ]
  },
  {
    id: "7",
    name: "Report",
    icon: "library_books",
    url: MENU_PRESENSI.MANAJEMEN_LAPORAN,
    children: [
      {
        id: "7.1",
        name: "Kedisiplinan Pegawai",
        icon: "",
        url: MENU_PRESENSI.KEDISIPLINAN_PEGAWAI
      },
      {
        id: "7.2",
        name: "Detail Kehadiran",
        icon: "",
        url: MENU_PRESENSI.DETAIL_KEHADIRAN
      },
      {
        id: "7.3",
        name: "Rekapitulasi Kehadiran",
        icon: "",
        url: MENU_PRESENSI.REKAPITULASI_KEHADIRAN
      },
      {
        id: "7.4",
        name: "Rekapitulasi Cuti",
        icon: "",
        url: MENU_PRESENSI.REKAPITULASI_CUTI
      },
      // {
      //   id: "7.5",
      //   name: "Rekapitulasi Presensi",
      //   icon: "",
      //   url: MENU_PRESENSI.REKAPITULASI
      // },
      {
        id: "7.6",
        name: "Lampiran 1G",
        icon: "",
        url: MENU_PRESENSI.LAMPIRAN_1G
      }
    ]
  },
  {
    id: "8",
    name: "Pengaturan",
    icon: "settings",
    url: MENU_PRESENSI.PENGATURAN,
    children: [
      {
        id: "8.1",
        name: "Pengaturan Mesin Absen",
        icon: "",
        url: MENU_PRESENSI.PENGATURAN_MESIN_ABSEN
      },
      {
        id: "8.2",
        name: "Pengaturan Finger ID",
        icon: "",
        url: MENU_PRESENSI.PENGATURAN_FINGER_ID
      },
      {
        id: "8.3",
        name: "Pengaturan Admin",
        icon: "",
        url: MENU_PRESENSI.PENGATURAN_ADMIN
      },
      {
        id: "8.4",
        name: "Berita",
        icon: "",
        url: MENU_PRESENSI.BERITA
      },
      {
        id: "8.5",
        name: "Persen Tukin",
        icon: "",
        url: MENU_PRESENSI.CONFIG_PERSEN_TUKIN
      }
    ]
  }
];

export function LIST_MENU(role = USER_ROLE.USER, isPresensi = true) {
  let navList = [];
  if (
    role == USER_ROLE.USER ||
    role == USER_ROLE.ADMIN ||
    role == USER_ROLE.ADMIN_PUSAT ||
    role == USER_ROLE.ADMIN_SATKER ||
    role == USER_ROLE.ADMIN_ESELON_3 ||
    role == USER_ROLE.ADMIN_ESELON_4 ||
    role == USER_ROLE.MANAGER_SATKER ||
    role == USER_ROLE.MANAGER_PUSAT
  ) {
    let type = isPresensi ? "PRESENSI" : "SIMPEG";
    if (type == "PRESENSI") {
      navList = NAV_LIST_PRESENSI.filter(d =>
        MENU_ACCESS[role + "_" + type].find(d2 => d2 == d.url)
      );
    } else {
      navList = NAV_LIST_SIMPEG.filter(d =>
        MENU_ACCESS[role + "_" + type].find(d2 => d2 == d.url)
      );
    }
    navList = navList.map(d => {
      if (d.children) {
        d.children = d.children.filter(d2 => {
          return MENU_ACCESS[role + "_" + type].find(d3 => d3 == d2.url);
        });
      }
      return d;
    });
  } else {
    if (role.includes("PRESENSI")) {
      navList = NAV_LIST_PRESENSI.filter(d =>
        MENU_ACCESS[role].find(d2 => d2 == d.url)
      );
    } else {
      navList = NAV_LIST_SIMPEG.filter(d =>
        MENU_ACCESS[role].find(d2 => d2 == d.url)
      );
    }
  }
  return navList;
}
